const translationGe = {
  flights: "რეისები",
  receive_packages: "მიიღე ამანათები",
  from_Turkey_and_from_China: "მთელი მსოფლიოდან",
  email_or_password_is_incorrect: "ელ.ფოსტა ან პაროლი არასწორია!",
  email: "ელ. ფოსტა",
  password: "პაროლი",
  remember: "დამახსოვრება",
  password_recovery: " პაროლის აღდგენა",
  login: "ავტორიზაცია",
  register: "რეგისტრაცია",
  why_expressgroup: "რატომ ExpressGroup?",
  our_advantages: "ჩვენი უპირატესობები",
  prices: "ფასები",
  turkey: "თურქეთი",
  china: "ჩინეთი",
  china_land: "ჩინეთი სახმელეთო",
  usa: "ამერიკა",
  bulk_shipments_are_subject_to_a_different_rate_based_on_actual_weights:
    "დიდი რაოდენობით ტვირთზე მოქმედებს სპეც (დაბალი) ტარიფი, აღნიშნულთან დაკავშირებით მოგვწერეთ დეტალები",
  what_ExpressGroup_offers: "რას გთავაზობთ ExpressGroup?",
  our_services: "ჩვენი სერვისები",
  contact: "კონტაქტი",
  write_us: "მოგვწერეთ",
  send_us_an_email_via_mail: "გამოგვიგზავნეთ შეტყობინება ელ. ფოსტის მეშვეობით",
  call_us: "დაგვირეკეთ",
  contact_us_only_during_working_hours:
    "დაგვიკავშირდით მხოლოდ სამუშაო საათებში",
  filials: "ფილიალები",
  mon_fri: "ორშ-პარ",
  saturdey: "შაბათი",
  viev_on_the_map: "რუკაზე ნახვა",
  all_rights_reserved: "ყველა უფლება დაცულია",
  terms_of_use_of_the_site: "საიტის გამოყენების პირობები",
  about_company: "კომპანიის შესახებ",
  priorities: "უპირატესობები",
  services: "სერვისები",
  language: "ენა",
  profile: "პროფილი",
  parametrs: "პარამეტრები",
  transactions: "ტრანზაქციები",
  dark_mode: "მუქი დიზაინი",
  log_out: "გასვლა",
  ballance: "ბალანსი",
  payable: "გადასახდელი",
  parcels: "ამანათები",
  faq: "ხშირად დასმული კითხვები",
  top_up: "ბალანსის შევსება",
  payment_by_card: "ბარათით გადახდა",
  service_fee_for_different_bank_cards:
    "მომსახურების საკომისიო სხვადასხვა ბანკის ბარათზე",
  pay: "გადახდა",
  enter_amount: "შეიყვანეთ თანხა",
  banks1: " თიბისი,ლიბერთი,ჰალიკი,ბაზის ბანკი,ტერა ბანკი",
  banks2: "საქართველოს ბანკი, ქართუ ბანკი, პროკრედიტ ბანკი",
  room: "ოთახი",
  all: "ყველა",
  payment: "გადახდები",
  general: "ზოგადი",
  shops: "მაღაზიები",
  addresses: "მისამართები",
  for_woman: "ქალებისთვის",
  for_man: "მამაკაცებისთვის",
  for_childrens: "ბავშვებისთვის",
  self_care_products: "თავის მოვლის საშუალებები",
  residence: "საყოფაცხოვრებო",
  brend_shops: "ბრენდ მაღაზიები",
  add_new_address: "ახალი მისამართის დამატება",
  name: "სახელი",
  address: "მისამართი",
  save: "შენახვა",
  flight: "რეისი",
  arrived: "ჩამოფრინდა",
  departure: "ჩამოფრინდება",
  pay_all: "ყველას გადახდა",
  from_china: "ჩინეთიდან",
  from_china_land: "ჩინეთიდან (სახმელეთო)",
  from_turkey: "თურქეთიდან",
  from_america: "ამერიკიდან",
  parcels: "ამანათები",
  in_the_warehouse: "საწყობში",
  sent: "გამოგზავნილი",
  received: "მიღებული",
  arrival_time: "ჩამოსვლის სავარაუდო დრო",
  payed: "გადახდილია",
  weight: "წონა",
  transportation: "ტრანსპორტირება",
  declaration: "დეკლარირება",
  details: "დეტალები",
  tracking: "თრექინგი",
  courier_service: "საკურიერო მომსახურება",
  products: "პროდუქტები",
  sum: "ჯამი",
  personal_parcel: "პირადი გზავნილი",
  web_site: "ვებ გვერდი",
  insurence: "დაზღვევა",
  comment: "კომენტარი",
  declarationn: "დეკლარირება",
  add_new_product: "ახალი პროდუქტის დამატება",
  delete: "წაშლა",
  add_invoice: "ინვოისი მიბმა",
  price: "ფასი",
  category: "კატეგორია",
  add: "დამატება",
  "სხვადასხვა მზა ნაწარმი": "სხვადასხვა მზა ნაწარმი",
  "ავტო ნაწილები": "ავტო ნაწილები",
  "აუდიო აპარატურა": "აუდიო აპარატურა",
  "აუდიო აპარატურის ნაწილი": "აუდიო აპარატურის ნაწილი",
  ბიჟუტერია: "ბიჟუტერია",
  გასაღები: "გასაღები",
  დანა: "დანა",
  "ვიდეო აპარატურა": "ვიდეო აპარატურა",
  "ვიდეო აპარატურის ნაწილი": "ვიდეო აპარატურის ნაწილი",
  თეთრეული: "თეთრეული",
  "თვითწებვადი ლენტი (სკოჩი)": "თვითწებვადი ლენტი (სკოჩი)",
  კომპიუტერი: "კომპიუტერი",
  "კომპიუტერის ნაწილი": "კომპიუტერის ნაწილი",
  "კოსმეტიკური საშუალებები": "კოსმეტიკური საშუალებები",
  მედიკამენტები: "მედიკამენტები",
  "მინის ნაწარმი": "მინის ნაწარმი",
  "მოტოციკლის ნაწილები": "მოტოციკლის ნაწილები",
  "მუსიკალური ინსტრუმენტები": "მუსიკალური ინსტრუმენტები",
  "მუსიკალური ინსტრუმენტის ნაწილი": "მუსიკალური ინსტრუმენტის ნაწილი",
  მცენარეები: "მცენარეები",
  "ნაბეჭდი პროდუქცია": "ნაბეჭდი პროდუქცია",
  პარფიუმერია: "პარფიუმერია",
  "პლასტმასის საკანცელარიო ნივთები": "პარფიუმერია",
  "პლასტმასის სასადილო, სამზარეულო ჭურჭელი":
    "პლასტმასის სასადილო, სამზარეულო ჭურჭელი",
  "რეზინის ნაწარმი": "რეზინის ნაწარმი",
  საათი: "საათი",
  "საზომ-საკონტროლო ხელსაწყოები": "საზომ-საკონტროლო ხელსაწყოები",
  სათამაშო: "სათამაშო",
  სათვალე: "სათვალე",
  საკეტი: "საკეტი",
  "საკვები დანამატები": "საკვები დანამატები",
  "სანათი მოწყობილობები": "სანათი მოწყობილობები",
  "საოფისე ინვენტარი": "საოფისე ინვენტარი",
  "სარეცხი და საწმენდი საშუალებები": "სარეცხი და საწმენდი საშუალებები",
  სასწორი: "სასწორი",
  "საყოფაცხოვრებო ავეჯი": "საყოფაცხოვრებო ავეჯი",
  "საყოფაცხოვრებო ტექნიკა": "საყოფაცხოვრებო ტექნიკა",
  "სპორტული ინვენტარი": "სპორტული ინვენტარი",
  ტანსაცმელი: "ტანსაცმელი",
  ტელეფონი: "ტელეფონი",
  ტენტი: "ტენტი",
  "ფაიფურის ჭურჭელი": "ფაიფურის ჭურჭელი",
  ფეხსაცმელი: "ფეხსაცმელი",
  "ფოტო აპარატურა": "ფოტო აპარატურა",
  "ფოტო აპარატურა": "ფოტო აპარატურა",
  "ქსელური მოწყობილობა": "ქსელური მოწყობილობა",
  ჩანთა: "ჩანთა",
  "ხელის ინსტრუმენტები": "ხელის ინსტრუმენტები",
  "სხვადასხვა ელექტრონული მოწყობილობები":
    "სხვადასხვა ელექტრონული მოწყობილობები",
  invoice: "ინვოისი",
  inv_name: "დასახელება: შპს ..ექსპრეს გრუპი",
  inv_identification_code: "ს/კ: 405427364",
  inv_phone: "ტელეფონი: 0 322 197 192",
  inv_email: "ელ.ფოსტა: info@expressgroup.ge",
  inv_address: "მისამართი: გაზაფხულის ქუჩა 4",
  recipient: "მიმღები",
  fullname: "სახელი გვარი",
  id: "პ/ნ",
  room_id: "ოთახის ნომერი",
  phone: "ტელეფონი",
  email: "ელ.ფოსტა",
  tracking_number: "ამანათის ნომერი",
  exp_ltd: "შპს ,,ექსპრეს გრუპი",
  bank_details: "ბანკის რეკვიზიტები",
  bank_code: "ბანკის კოდი",
  inv_pr: "დანიშნულება: თანხის დარიცხვისას მიუთითეთ ოთახის ნომერი",
  profile: "პროფილი",
  change_photo: "ფოტოს შეცვლა",
  name_ge: "სახელი ქართულად",
  surname_ge: "გვარი ქართულად",
  name_en: "სახელი ლათინურად",
  surname_en: "გვარი ლათინურად",
  birth_date: "დაბადების თარიღი",
  id_num: "პირადი ნომერი",
  gender: "სქესი",
  city: "ქალაქი",
  man: "ქალი",
  woman: "კაცი",
  transactions: "ტრანზაქციები",
  enroll: "ჩარიცხვა",
  successful: "წარმატებული",
  unsuccessful: "წარუმატებელი",
  parametrs: "პარამეტრები",
  manage_your_personal_information: "მართე შენი პერსონალური ინფორმაცია",
  security: "უსაფრთხოება",
  view_or_change_your_password: "ნახე ანდა შეცვალე შენი პაროლი",
  my_addresses: "ჩემი მისამართები",
  manage_your_address_for_better_courier_service:
    "მართე შენი მისამართი უკეთესი საკურიერო მომსახურების მიზნით",
  notification: "შეტყობინებები",
  manage_the_information_you_want_to_receive_via_SMS_and_Email:
    "მართე ინფორმაცია, რომლის მიღებაც გსურს SMS და Email_ით",
  passwords_don_match:
    "ძველი პაროლი არასწორია ან ახალი პაროლი არ ემთხვევა განმეორებულს",
  password_changed: "პაროლი წარმატებით შეიცვალა",
  to_keep_your_password_safe_please_observe_the_following_Recommendations:
    "იმისათვის რომ პაროლი იყოს უსაფრთხო, გთხოვთ დაიცვათ შემდეგირეკომენდაციები",
  must_contain_at_least_characters: "უნდა შეიცავდეს მინიმუმ 6 სიმბოლოს",
  it_is_recommended_to_include_at_least_one_number:
    "რეკომენდებულია შეიცავდეს მინიმუმ ერთ რიცხვს",
  it_is_recommended_to_include_at_least_one_different_characte:
    "რეკომენდებულია შეიცავდეს მინიმუმ ერთ განსხვავებულ სიმბოლოს",
  do_not_use_simple_and_common_passwords:
    "არ გამოიყენოთ მარტივი და გავრცელებული პაროლები",
  password: "პაროლი",
  new_password: "ახალი პაროლი",
  conf_password: "გაიმეორეთ ახალი პაროლი",
  parcel_warehouse: "ამანათის მიღება საწყობში",
  parsel_sent: "ამანათი გამოგზავნილია",
  parsel_arrived: "ამანათი ჩამოსულია",
  parcel_received: "ამანათი გატანილია",
  registration: "რეგისტრაცია",
  step: "ეტაპი",
  contact_info: "კონტაქტი",
  personal_info: "პირადი ინფორმაცია",
  user_type: "მომხმარებლის ტიპი",
  physical_person: "ფიზიკური პირი",
  legal_entity: "იურიდიული პირი",
  next: "გაგრძელება",
  back: "უკან",
  underage: "არასრულწლოვანი!",
  incorrect_id: "პ.ნ არავალიდურია!",
  dir_name_ge: "დირექტორის სახელი (Ge)",
  dir_surname_ge: "დირექტორის გვარი (Ge)",
  dir_name_en: "დირექტორის სახელი (En)",
  dir_surname_en: "დირექტორის გვარი (En)",
  dir_id: "დირექტორის პირადი ნომერი",
  error: "დაფიქსირდა შეცდომა",
  password_dont_match: "პაროლები არ ემთხვევა!",
  invalid_email: "მეილი არავალიდურია!",
  i_agree_the_terms_and_conditions_of_the_site:
    "ვეთანხმები საიტის გამოყენების წესებსა და პირობებს",
  in_warehouse: "საწყობში მისული",
  change_pass_email: "პაროლის შეცვლა ელ. ფოსტით",
  change_pass: "პაროლის შეცვლა",
  success: "მოქმედება შესრულდა წარმატებით!",
  parcels_arrived_at_the_warehouse: "საწყობში მისული ამანათები",
  search: "ძებნა",
  transportation: "ტრანსპორტირება",
  choose: "აირჩიე",
  bag: "ჩანთა",
  receive: "მიღება",
  user_information: "მომხმარებელის ინფორმაცია",
  parcel_information: "გზავნილის ინფორმაცია",
  parcel_id: "გზავნილის ID",
  price_sum: "დეკლ. თანხის ჯამი",
  date: "თარიღი",
  secured: "დაზღვეულია",
  unsecured: "არაა დაზღვეული",
  send: "გამოგზავნა",
  company_name: "კომპანიის სახელი (En)",
  company_ic: "კომპანიის ს/კ",
  parcel: "ამანათის",
  symbol: "9 სიმბოლო!",
  trancking_number_is_alreay_added: "მსგავსი Barcode უკვე დამატებულია",
  el_code_err: "ელ.ფოსტაზე მითითებული კოდი არ ემთხვევა ან ველი ცარიელია!",
  phone_code_err: "ტელ.ნომერზე მითითებული კოდი არ ემთხვევა ან ველი ცარიელია!",
  email_registerd: "ელ.ფოსტა უკვე დარეგისტრირებულია ან ველები ცარიელია!",
  phone_registerd: "ტელეფონი უკვე დარეგისტრირებულია ან ველები ცარიელია!",
  id_registerd: "პირადი ნომერი უკვე დარეგისტრირებულია ან ველები ცარიელია!",
  sc_registerd: "საიდენთიფიკაციო კოდი დარეგისტრირებულია ან ველები ცარიელია",
  does_not_match: "კოდი არ დაემთხვა ან დაფიქსირდა სხვა სახის შეცდომა!",
  recover_password: "პაროლის აღდგენა",
  password_sent: "პაროლი გამოიგზავნა !",
  user_do_not_exists: "მომხმარებელი ვერ მოიძებნა !",
  log_out: "გამოსვლა",
  declaration_successfull: "ამანათი წარმატებით დადეკლარირდა",
  add_new_address: "ახალი მისამართის დამატება",
  update_address: "მისამართის განახლება",
  update: "განახლება",
  email_code: "ელ-ფოსტის კოდი",
  phone_code: "ტელეფონის კოდი",
  send_code: "კოდის გამოგზავნა",
  confirm: "დადასტურება",
  product: "პროდუქცია",
  fill_all_fields: "შეავსეთ ყველა ველი!",
  pay_successfull: "გადახდა შესრულდა წარმატებით!",
  clearance_0: "განსაბაჟებელია",
  clearance_1: "განბაჟებულია",
  messeges: "მესიჯები",
  codes: "კოდები",
  else: "სხვა საიტები",
  dec_text:
    "პ.ს თუ ნივთის ან ერთ რეისზე მოხვედრილი ამანათების ჯამური ღირებულება აღემატება 300 ლარს ან წონა 30 კგ-ს, ამანათი ექვემდებარება განბაჟებას, გთხოვთ ატვირთოთ ინვოისი!",
  "მოცულობითი წონით": "მოცულობითი წონით",
  "რეალური წონით": "რეალური წონით",
  invalid_phone: "ტელეფონის ნომერი არავალიდურია!",
  fill_phone_required: "ტელეფეონის ნომრის ველი ცარიელია, გთხოვთ შეავსეთ!",
  office: "ოფისი",
  please_fill_website_field: "ვებ გვერდის ველი სავალდებულოა!",
  check_currior_box:
    "მონიშნეთ ველი საკურიერო მომსახურება, წინააღმდეგ შემთხვევაში ცვლილებები არ დაფიქსირდება!",
  where_you_take_package: "სად გინდათ აიღოთ ამანათი ?",
  no_amount_was_specified: "მიუთითეთ სასურველი თანხა!",
  pay_transportation:
    "საკურიეროს გაფორმებამდე გთხოვთ დაფაროთ ტრანსპორტირების გადასახადი",
  dec_pack_err: "გთხოვთ დაამატოთ ახალი პროდუქტი",
  address_required:
    "მისამართის მითითება სავალდებულოა, სასურველი მისამართის განახლება/დამატება შესაძლებელია: პარამეტრები -> ჩემი მისამართები",
  chose_filial: "აირჩიე ფილიალი",
  setting_filil_text: "მონიშნეთ რომელი ფილიალიდან გსურთ ამანათის გატანა",
  choose_city: "აირჩიე ქალაქი",
  payment_success: "გადახდა განხორციელდა წარმატებით",
  all_paid: "გადაუხდელი ამანათი არ მოიძებნა",
  not_enough_money: "ანგარიშზე არაა საკმარისი თანხა",
  length: "სიგრძე",
  width: "სიგანე",
  height: "სიმაღლე",
  calculate: "გამოთვლა",
  calc_volume_weight: "მოცულობითი წონის გამოთვლა",
  value_cant_0: "მნიშვნელობა არ შეიძლება იყოს ნული",
  fields_empty: "შეავსეთ ყველა ველი",
  volume_weight: "მოცულობითი წონა",
  already_paid: "გადახდილია",
  courier_clearance: "საკურიეროს გაფორმება",
  courier_description:
    "მონიშნეთ სასურველი ამანათები რომლებზეც გსურთ რომ გაფორმდეს საკურიერო სერვისი",
  office_notification_text: "მიუთითეთ ოფისი რომლიდანაც გსურთ ამანათის გატანა",
  chose_filial_caution:
    "ფილიალის ცვლილება შეეხება მხოლოდ ამ ცვლილების შემდეგ უცხოეთის საწყობში მისულ ამანთებს",
  select_all: "ყველას მონიშვნა",
  land: "სახმელეთო",
  air: "საჰაერო",
  instructions: "ინსტრუქციები",
  video_instructions: "ვიდეო ინსტრუქციები",
  prices_text: "წონა ითვლება 100 გრამის სიზუსტით",
  cm: "სმ",
  kg: "კგ",
  greece: "საბერძნეთი",
  curriorText: `თბილისში 3კგ-მდე ამანათის მიტანის ღირებულება არის 5,50 ლარი. 
    3დან - 10კგ-მდე ამანათის მიტანის ღირებულება 10 ლარი. რეგიონში 5კგ-მდე ამანათის მიტანის ღირებულება 
    არის 7 - 12 ლარი. 5კგ-დან ზემოთ მოქმედებს სხვადასხვა ტარიფები, კონკრეტული მისამართის მიხედვით`,
  curriorText2: `პირველ რიგში გთხოვთ დაფაროთ ტრანსპორტირების საფასური რომ გამოგიჩნდეთ ამანათები საკურიეროს გასაფორმებლად`,
  foreigner: "უცხო ქვეყნის მოქალაქე",
  cheque: "20 ლარიანი ქვითარი",
  yellow: "ყვითელშია",
  yellowCautionText:
    "თქვენი ამანათი(ები) მოხვდა ყვითელ დერეფანში. გთხოვთ დაუყოვნებლივ ატვირთოთ ინვოისები სათითაოდ ამანათებზე",
  advertising: "სარეკლამო",
  deleteAccount: "პროფილის წაშლა",
  autoParts: "მანქანის ნაწილები",
  other: "სხვა",
  parcel_clarence: "ამანათის განბაჟება",
  reallyWantClarenceText: "ნამდვილად გსურთ ნივთის განბაჯება?!",
  clarence_caution_text:
    "გაითვალისწინეთ! არ მონიშნოთ განბაჟება თუ თქვენი ამანათების ჯამური ღირებულება ერთ რეისზე, არ აღემატება 300 ლარს; არ აღემატება 30 კილოს; არ არის რამოდენიმე ერთგვაროვანი ნივთი; ან თავად არ გსურთ რომ განაბაჟოთ თქვენი ამანათები.",
  yes: "დიახ",
  no: "არა",
  england: "ბრიტანეთი",
  რეალური: "რეალური",
  მოცულობითი: "მოცულობითი",
  pending: "მომლოდინე",
  currior: "საკურიერო",
};

export default translationGe;
