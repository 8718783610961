import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { TbPlaneDeparture, TbPlaneArrival } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const FlightsModalCard = ({ data }) => {
  //translations
  const { t } = useTranslation();
  //is arrived
  const [isArrived, setIsArrived] = useState(false);

  useEffect(() => {
    if (data.flight_status == 1) {
      setIsArrived(true)
    } else {
      setIsArrived(false)
    }
  }, [data])

  //place icon
  const PlaneIcon = () => {
    return isArrived ? (
      <TbPlaneArrival className="font-semibold text-teal_A700 " />
    ) : (
      <TbPlaneDeparture className="font-semibold text-indigo_400" />
    );
  };

  return (
    <div
      className="flex flex-1 flex-row gap-5 items-center 
        outline outline-[1px] outline-black_900_0a p-3 shadow-bs w-[100%] sm:"
    >
      <div
        className="flex flex-col items-center
        justify-center rounded-radius50 p-2 border border-dashed border-gray-200"
      >
        <Button
          className="flex h-10 w-10 items-center justify-center rounded-radius50"
          variant={`${isArrived ? "icbFillTealA70014" : "icbFillIndigo40014"}`}
          shape={"circular"}
        >
          <PlaneIcon />
        </Button>
      </div>
      <div className="flex flex-1 flex-col items-start justify-start">
        <div className="flex flex-row gap-[8px] justify-start w-[auto] ">
          <Text
            className="font-normal not-italic text-white text-left w-[auto]"
            variant="body10"
          >
            {t("flight")}:
          </Text>
          <Text
            className={`font-semibold text-left ${isArrived ? "text-teal_A700" : "text-indigo_400"}`}
            variant="body10"
          >
            {data.number}
          </Text>
        </div>
        <div className="flex flex-row gap-[8px] items-start justify-start w-[auto]">
          <Text
            className="font-normal not-italic text-white text-left w-[auto]"
            variant="body10"
          >
            {isArrived ? t("arrived") : t("arrival_time")}:
          </Text>
          <Text
            className="font-semibold text-white text-left w-[auto]"
            variant="body10"
          >
            {data.date}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default FlightsModalCard;
